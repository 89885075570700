import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { InputGroup, InputGroupAddon, Input } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "input-group"
    }}>{`Input group`}</h1>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<InputGroup>\n  <InputGroupAddon>+47</InputGroupAddon>\n  <Input />\n</InputGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      InputGroup,
      InputGroupAddon,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <InputGroup mdxType="InputGroup">
    <InputGroupAddon mdxType="InputGroupAddon">+47</InputGroupAddon>
    <Input mdxType="Input" />
  </InputGroup>
    </Playground>
    <h2 {...{
      "id": "addon-to-the-right"
    }}>{`Addon to the right`}</h2>
    <Playground __position={1} __code={'<InputGroup>\n  <Input />\n  <InputGroupAddon>%</InputGroupAddon>\n</InputGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      InputGroup,
      InputGroupAddon,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <InputGroup mdxType="InputGroup">
    <Input mdxType="Input" />
    <InputGroupAddon mdxType="InputGroupAddon">%</InputGroupAddon>
  </InputGroup>
    </Playground>
    <h2 {...{
      "id": "alternative"
    }}>{`Alternative`}</h2>
    <p>{`In flows with only a few fields you might use the prop `}<inlineCode parentName="p">{`alternative`}</inlineCode>{` to get a gray input field that stands out more.`}</p>
    <Playground __position={2} __code={'<InputGroup>\n  <InputGroupAddon alternative>+47</InputGroupAddon>\n  <Input alternative />\n</InputGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      InputGroup,
      InputGroupAddon,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <InputGroup mdxType="InputGroup">
    <InputGroupAddon alternative mdxType="InputGroupAddon">+47</InputGroupAddon>
    <Input alternative mdxType="Input" />
  </InputGroup>
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={InputGroup} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      